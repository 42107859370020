import React, { useEffect, useRef } from 'react';
import Prism from 'prismjs';
// Import base Prism styling and additional languages
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-sass';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-java';
import { CodeBlockWrapper, LanguageTag } from './CodeBlock.styles';

const SUPPORTED_LANGUAGES = {
  javascript: 'JavaScript',
  jsx: 'JSX',
  html: 'HTML',
  css: 'CSS',
  scss: 'SCSS',
  sass: 'Sass',
  python: 'Python',
  java: 'Java',
  typescript: 'TypeScript',
  tsx: 'TSX',
  bash: 'Shell',
  json: 'JSON',
  markdown: 'Markdown',
  text: 'Plain Text'
};

// GitHub Plus Theme - Colors from official repository
// https://github.com/thenikso/github-plus-theme
const githubPlusTheme = `
  /* GitHub Plus Theme */
  code[class*="language-"],
  pre[class*="language-"] {
    color: #24292e;
    font-family: 'Fira Code', 'SF Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    tab-size: 2;
    hyphens: none;
    background: #ffffff;
  }

  pre[class*="language-"]::selection,
  code[class*="language-"]::selection,
  pre[class*="language-"] ::selection,
  code[class*="language-"] ::selection {
    background: #e1e4e8;
  }

  /* General syntax colors */
  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #6a737d;
  }

  .token.punctuation {
    color: #24292e;
  }

  .token.selector,
  .token.tag {
    color: #22863a;
  }

  .token.property,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.attr-value,
  .token.deleted {
    color: #005cc5;
  }

  .token.string,
  .token.char {
    color: #032f62;
  }

  .token.attr-name {
    color: #6f42c1;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #24292e;
  }

  .token.atrule,
  .token.keyword {
    color: #d73a49;
  }

  .token.function,
  .token.class-name {
    color: #6f42c1;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #22863a;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  /* Type and interface colors */
  .token.builtin,
  .token.inserted {
    color: #22863a;
  }
  
  /* Parameter highlighting */
  .token.parameter {
    color: #E27F2D;
  }
  
  /* JSX specific */
  .language-jsx .token.tag {
    color: #22863a;
  }

  .language-jsx .token.punctuation {
    color: #24292e;
  }

  .language-jsx .token.attr-name {
    color: #6f42c1;
  }

  .language-jsx .token.attr-value,
  .language-jsx .token.string {
    color: #032f62;
  }

  .language-jsx .token.keyword {
    color: #d73a49;
  }

  /* Markdown specific */
  .language-markdown .token.title,
  .language-markdown .token.section {
    color: #6f42c1;
    font-weight: bold;
  }

  .language-markdown .token.list {
    color: #d73a49;
  }

  .language-markdown .token.url,
  .language-markdown .token.link {
    color: #005cc5;
    text-decoration: underline;
  }
`;

// Use a static ID for the style element to avoid duplicates
const STYLE_ELEMENT_ID = 'github-plus-prism-theme';

// Create or update a style element for the theme
const ensureThemeStyleElement = () => {
  let styleElement = document.getElementById(STYLE_ELEMENT_ID);
  
  if (!styleElement) {
    styleElement = document.createElement('style');
    styleElement.id = STYLE_ELEMENT_ID;
    styleElement.textContent = githubPlusTheme;
    document.head.appendChild(styleElement);
  }
  
  return styleElement;
};

const CodeBlock = ({ code, language }) => {
  const codeRef = useRef(null);
  const normalizedLanguage = language?.toLowerCase() || 'text';
  const displayLanguage = SUPPORTED_LANGUAGES[normalizedLanguage] || 'Plain Text';

  useEffect(() => {
    // Ensure the theme style is applied (shared across all instances)
    ensureThemeStyleElement();
    
    // Apply syntax highlighting
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current, false);
    }
    
    // No cleanup needed for individual components
    // The style element is now shared and remains in the DOM
  }, [code, normalizedLanguage]);

  return (
    <CodeBlockWrapper>
      <LanguageTag>{displayLanguage}</LanguageTag>
      <pre>
        <code
          ref={codeRef}
          className={`language-${normalizedLanguage}`}
          dangerouslySetInnerHTML={{ __html: code }}
        />
      </pre>
    </CodeBlockWrapper>
  );
};

export default CodeBlock;
