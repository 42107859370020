import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FloatingHeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    width: 85%;
    max-width: 960px;
    position: fixed;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    background-color: #ffffffD0;
    backdrop-filter: blur(5px);
    border-radius: 50px;
    border: 1px solid #e6e6e6;

    @media (max-width: 768px) {
        width: 90%;
        max-width: 90%;
    }
`;

export const Logo = styled.img`
    width: 50px;
    margin-right: auto;
    aspect-ratio: 1/1;
`;

export const Nav = styled.nav`
    display: flex;
    align-items: center;
`;

export const NavItem = styled(Link)`
    text-decoration: none;
    color: #000000;
    font-weight: 600;
    margin-right: 1.3rem;
    cursor: pointer;
`;

export const PhoneButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: linear-gradient(135deg, #FF1493 0%, #FF69B4 100%);
    border-radius: 50%;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    
    svg {
        width: 20px;
        height: 20px;
    }
    
    &:hover {
        transform: scale(1.1);
        background-color: #333333;
    }

    @media (max-width: 600px) {
        height: 35px;
        width: 35px;
        padding: 0.7rem;
    }
`;

export const NavCta = styled(Link)`
    padding: 0.5rem 1rem;
    background: linear-gradient(135deg, #FF1493 0%, #FF69B4 100%);
    color: #ffffff;
    border-radius: 50px;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
`;