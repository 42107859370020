import styled from 'styled-components';

export const CodeBlockWrapper = styled.div`
  margin: 1.5em 0;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #e1e4e8;
  font-size: 0.9em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
  border-radius: 1.25rem;

  pre {
    margin: 0 !important;
    padding: 1rem !important;
    overflow-x: auto;
    font-family: 'Fira Code', 'SF Mono', SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace !important;
    font-size: 0.9em !important;
    line-height: 1.5 !important;
    background: transparent !important;
    
    &::-webkit-scrollbar {
      height: 8px;
      background: #f6f8fa;
    }

    &::-webkit-scrollbar-thumb {
      background: #d1d5da;
      border-radius: 3px;

      &:hover {
        background: #959da5;
      }
    }

    code {
      background: none !important;
      padding: 0 !important;
      font-family: inherit !important;
      font-size: inherit !important;
      text-shadow: none !important;
      white-space: pre !important;
      word-spacing: normal !important;
      word-break: normal !important;
      word-wrap: normal !important;
      tab-size: 2 !important;
      hyphens: none !important;
    }
  }
`;

export const LanguageTag = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 1.25rem;
  padding: 3px 10px;
  background: #FFF;
  color: #000;
  border: 1px solid #e1e4e8;
  font-size: 0.75em;
  font-family: 'Segoe UI', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, sans-serif;
  opacity: 0.8;
  z-index: 5;
`;
