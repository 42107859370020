import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PortableText } from '@portabletext/react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/toast-styles.css';
// Import Prism core and theme
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css';

// Base language (required for others)
import 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';

// Languages
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markdown';

import {
  Article,
  Categories,
  Category,
  Content,
  BlogInfo,
  BlogHeadline,
  AuthorSectionContainer,
  AuthorSection,
  AuthorImage,
  AuthorInfo,
  ShareButton,
  BlogPostImage,
  ShareSection,
  ShareText,
  ShareButtonStyled,
  AccessibilityControls,
  TextToSpeechButton,
  VoiceOptionsButton,
  VoiceOptionsPanel,
  VoiceOptionGroup,
  VoiceOptionsField,
  LanguageNotice,
  AuthorInfoContainer,
  TopInfoContainer,
  ReadingTime
} from './BlogPost.styles';
import {
  BlocksContainer,
  BlurredOverlay,
  ErrorContainer,
  FluidContainer,
  Heading,
  Headline,
  Paragraph,
  SubHeading,
  WallpaperWrapper
} from '../_Common/common.styles';
import Blob from '../Blob/Blob';
import { usePost } from '../../Hooks/UsePost';
import BackButton from '../BackButton/BackButton';
import { useTranslation } from 'react-i18next';
import CTABlock from '../../Blocks/CTABlock';
import { postsService } from '../../Services/posts'; // Assuming you have this service
import RecentPostsSidebar from '../RecentPostsSidebar/RecentPostsSidebar';
import { useRecentPosts } from '../../Hooks/useRecentPosts';
import CodeBlock from './CodeBlock';

// Function to calculate reading time based on words
const calculateReadingTime = (content) => {
  if (!content || !Array.isArray(content)) return 0;
  
  // Extract all text content from the portable text
  let textContent = '';
  
  const extractText = (block) => {
    if (block._type === 'block' && block.children) {
      block.children.forEach(child => {
        if (child._type === 'span' && child.text) {
          textContent += child.text + ' ';
        }
      });
    }
  };
  
  // Process all blocks to get the total text
  content.forEach(block => {
    extractText(block);
  });
  
  // Count words (splitting by whitespace)
  const words = textContent.trim().split(/\s+/).length;
  
  // Calculate reading time (assuming average reading speed of 200 words per minute)
  const readingTimeMinutes = Math.ceil(words / 200);
  
  return readingTimeMinutes;
};

const toastConfig = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

const BlogPost = () => {
  const { slug } = useParams(); // Get the current slug from the URL parameters
  const navigate = useNavigate(); // Used to programmatically navigate to other routes
  const { t, i18n } = useTranslation(); // i18n hook for translations
  const currentLang = i18n.language; // Get the current language
  const { recentPosts, loading: recentPostsLoading, error: recentPostsError } = useRecentPosts(currentLang);

  // Fetch the post data based on the current language
  const { post, loading, error } = usePost(slug, currentLang); // Custom hook to fetch the post
  const [finalPost, setFinalPost] = useState(null); // State to hold the post to display
  
  // State for text-to-speech
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechSynthRef = useRef(null);
  const [selectedVoice, setSelectedVoice] = useState(null);
  
  // Function to load available voices
  const loadVoices = () => {
    if (window.speechSynthesis) {
      // Get all available voices
      const voices = window.speechSynthesis.getVoices();
      
      // Select the appropriate voice based on language
      selectVoiceForLanguage(voices, currentLang);
    }
  };
  
  // Function to select the correct voice for current language
  const selectVoiceForLanguage = (voices, language) => {
    if (!voices || voices.length === 0) return;
    
    const langCode = language === 'fr' ? 'fr' : 'en';
    
    if (langCode === 'en') {
      // For English, use Google UK English Male
      let bestVoice = voices.find(voice => 
        voice.name.toLowerCase().includes('google uk english male')
      );
      
      // Fall back to any English voice if needed
      if (!bestVoice) {
        bestVoice = voices.find(voice => 
          voice.lang.toLowerCase().includes('en')
        );
      }
      
      setSelectedVoice(bestVoice);
    } else {
      // For French, use Google French
      let bestVoice = voices.find(voice => 
        voice.lang.toLowerCase().includes('fr') && 
        voice.name.toLowerCase().includes('google')
      );
      
      // Fall back to any French voice if needed
      if (!bestVoice) {
        bestVoice = voices.find(voice => 
          voice.lang.toLowerCase().includes('fr')
        );
      }
      
      setSelectedVoice(bestVoice);
    }
  };
  
  // When language changes, update selected voice
  useEffect(() => {
    const voices = window.speechSynthesis?.getVoices();
    if (voices && voices.length > 0) {
      selectVoiceForLanguage(voices, currentLang);
    }
  }, [currentLang]);
  
  // Load voices when component mounts or when speech synthesis is ready
  useEffect(() => {
    if (window.speechSynthesis) {
      // Load voices immediately
      loadVoices();
      
      // Chrome loads voices asynchronously, so we need to listen for the voiceschanged event
      window.speechSynthesis.onvoiceschanged = loadVoices;
      
      return () => {
        window.speechSynthesis.onvoiceschanged = null;
      };
    }
  }, []);
  
  // Clean up speech synthesis when component unmounts
  useEffect(() => {
    return () => {
      if (window.speechSynthesis) {
        window.speechSynthesis.cancel();
      }
    };
  }, []);
  
  // Function to extract text content for text-to-speech
  const extractTextContent = (content) => {
    if (!content || !Array.isArray(content)) return '';
    
    let textContent = '';
    
    // Helper function to extract text from blocks
    const processBlock = (block) => {
      if (block._type === 'block' && block.children) {
        block.children.forEach(child => {
          if (child._type === 'span' && child.text) {
            textContent += child.text + ' ';
          }
        });
        textContent += '\n\n'; // Add paragraph breaks
      }
    };
    
    // Process all blocks
    content.forEach(block => {
      processBlock(block);
    });
    
    return textContent.trim();
  };
  
  // Function to start/stop text-to-speech
  const toggleTextToSpeech = () => {
    if (isSpeaking) {
      // Stop speaking
      if (window.speechSynthesis) {
        window.speechSynthesis.cancel();
      }
      setIsSpeaking(false);
    } else {
      // Start speaking
      if (window.speechSynthesis) {
        try {
          const textContent = extractTextContent(bodyContent);
          const utterance = new SpeechSynthesisUtterance(textContent);
          
          // Set language based on current site language
          utterance.lang = currentLang === 'fr' ? 'fr-FR' : 'en-US';
          
          // Apply selected voice if available
          if (selectedVoice) {
            utterance.voice = selectedVoice;
          }
          
          // Use default rate and pitch
          utterance.rate = 1;
          utterance.pitch = 1;
          
          // Split text into smaller chunks to improve reliability
          // This helps with longer texts and some problematic voices
          const maxChunkLength = 200; // characters per chunk
          
          if (textContent.length > maxChunkLength) {
            // For long content, break into sentences and speak sequentially
            const sentences = textContent.match(/[^.!?]+[.!?]+/g) || [textContent];
            let currentIndex = 0;
            
            // Function to speak the next sentence
            const speakNextSentence = () => {
              if (currentIndex < sentences.length) {
                const sentenceUtterance = new SpeechSynthesisUtterance(sentences[currentIndex]);
                sentenceUtterance.voice = selectedVoice;
                sentenceUtterance.lang = utterance.lang;
                sentenceUtterance.rate = 1;
                sentenceUtterance.pitch = 1;
                
                // When this sentence ends, move to the next one
                sentenceUtterance.onend = () => {
                  currentIndex++;
                  speakNextSentence();
                };
                
                // If speech is cancelled, this will be handled by the global cancel
                window.speechSynthesis.speak(sentenceUtterance);
              } else {
                // All sentences have been spoken
                if (currentIndex >= sentences.length) {
                  setIsSpeaking(false);
                }
              }
            };
            
            // Start speaking the first sentence
            speakNextSentence();
            setIsSpeaking(true);
          } else {
            // For short content, speak all at once
            // Store reference to utterance
            speechSynthRef.current = utterance;
            
            // Event handler for when speech ends
            utterance.onend = () => {
              setIsSpeaking(false);
            };
            
            // Start speaking
            window.speechSynthesis.speak(utterance);
            setIsSpeaking(true);
          }
        } catch (error) {
          console.error('Text-to-speech error:', error);
          toast.error(t('blog.textToSpeech.error', 'There was an error with the text-to-speech service. Please try again.'), toastConfig);
          setIsSpeaking(false);
        }
      } else {
        // Browser doesn't support speech synthesis
        toast.warning(t('blog.textToSpeech.notSupported', 'Text-to-speech is not supported by your browser.'), toastConfig);
      }
    }
  };
  
  // Function to extract a snippet from the blog content for use as excerpt
  const extractExcerpt = (content) => {
    if (!content || !Array.isArray(content)) return '';
    
    // Look for text blocks in the portable text content
    let textContent = '';
    
    // Process the first few blocks to create an excerpt
    for (let i = 0; i < content.length && textContent.length < 250; i++) {
      // Check if it's a text block
      if (content[i]._type === 'block' && content[i].children) {
        // Get text from the children
        content[i].children.forEach(child => {
          if (child._type === 'span' && child.text) {
            textContent += child.text + ' ';
          }
        });
      }
    }
    
    // Trim and limit to approximately 150 characters, ending at a word boundary
    if (textContent.length > 150) {
      textContent = textContent.substring(0, 150).trim();
      // Find the last space to avoid cutting words
      const lastSpace = textContent.lastIndexOf(' ');
      if (lastSpace > 0) {
        textContent = textContent.substring(0, lastSpace);
      }
      textContent += '...';
    }
    
    return textContent.trim();
  };

  useEffect(() => {
    const handlePostFallback = async () => {
      // Only attempt to fetch the alternate post if the current post is not found
      if (!post && !loading && !error) {
        try {
          // Fallback to the alternate language (fr if currentLang is en, and vice versa)
          const altLang = currentLang === 'en' ? 'fr' : 'en';
          const altPost = await postsService.getPostBySlug(slug, altLang);

          if (altPost) {
            // Fetch the unlocalized post by ID to check for the correct language version
            const unLocalizedPost = await postsService.getPostById(altPost[0]._id);

            if (unLocalizedPost?.slug?.[currentLang]) {
              const correctSlug = unLocalizedPost.slug[currentLang];
              navigate(`/blog/${correctSlug}`, { replace: true }); // Redirect to the correct URL
            } else {
              setFinalPost(altPost); // If no redirect needed, use the alternate language post
            }
          } else {
            setFinalPost(null); // If no post is found in either language
          }
        } catch (err) {
          console.error('Error fetching post by ID:', err);
        }
      } else {
        setFinalPost(post); // If the post is found, use it
      }
    };

    // Only run the fallback logic if post is not found
    if (!post) {
      handlePostFallback();
    } else {
      setFinalPost(post); // If post exists, set it as finalPost directly
    }
  }, [post, loading, error, currentLang, slug, navigate]); // Dependencies for useEffect

  // Format the date according to the current language
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(currentLang, options);
  };

  if (loading) {
    return <FluidContainer><Heading>{t('loading')}</Heading></FluidContainer>; // Show loading indicator while fetching data
  }

  if (error || !finalPost) {
    return (
      <ErrorContainer>
        <Headline>{t('errorLoadingContent')}</Headline>
        <SubHeading>{t('postNotFound')}</SubHeading>
        <RecentPostsSidebar recentPosts={recentPosts} formatDate={formatDate} />
        <Blob />
        <BlurredOverlay />
        <BackButton link={"blogs"} />
      </ErrorContainer>
    ); // Show error message if post is not found
  }

  // Extract and safely access all necessary fields from the post object
  const title = finalPost.title;
  const slugValue = finalPost.slug;
  const publishedAt = finalPost.publishedAt;
  const categories = finalPost.categories;
  
  // Ensure mainImageUrl is an absolute URL for social sharing
  let mainImageUrl = finalPost.mainImage?.asset?.url;
  
  // If the URL doesn't start with http/https, make it absolute
  if (mainImageUrl && !mainImageUrl.startsWith('http')) {
    mainImageUrl = `https://webcity.dev${mainImageUrl.startsWith('/') ? '' : '/'}${mainImageUrl}`;
  }
  
  // Fallback image if the post doesn't have a main image
  if (!mainImageUrl) {
    mainImageUrl = 'https://webcity.dev/webcity-logo-share.png';
  }
  
  // Ensure image is properly formatted for social sharing
  // Some platforms may not properly handle certain image formats or sizes
  // Convert query parameters to optimize the image if using a CDN like Sanity
  if (mainImageUrl.includes('sanity.io') && !mainImageUrl.includes('?')) {
    // Add width and format parameters for Sanity CDN
    mainImageUrl += '?w=1200&h=630&fit=crop&auto=format';
  }
  
  const authorName = finalPost.author?.name
  const authorImage = finalPost.author?.image?.asset?.url;
  const bodyContent = finalPost.body;
  const excerpt = extractExcerpt(bodyContent);
  
  // Calculate reading time for the blog post
  const readingTime = calculateReadingTime(bodyContent);
  
  // Get the full canonical URL for this blog post
  const canonicalUrl = `https://webcity.dev/blog/${slugValue}`;

  // Custom components for PortableText to handle any custom marks or blocks
  const portableTextComponents = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || '').startsWith('http') ? '_blank' : undefined;
        return (
          <a href={value?.href} target={target} rel={target === '_blank' ? 'noopener noreferrer' : undefined}>
            {children}
          </a>
        );
      },
      strong: ({ children }) => <strong>{children}</strong>,
      em: ({ children }) => <em>{children}</em>,
      customButton: (props) => {
        if (props.text === '[cntctBtn]') {
          return <CTABlock />;
        }
        return <span>{props.children}</span>;
      },
    },
    block: {
      // Default block handling
      normal: ({ children }) => {
        // Handle ctaButton special case
        const hasCtaBtn = children && children.some(child => 
          typeof child === 'string' && child.includes('[cntctBtn]')
        );
        
        if (hasCtaBtn) {
          return (
            <p>
              {children.map((child, i) => {
                if (typeof child === 'string' && child.includes('[cntctBtn]')) {
                  return child.split('[cntctBtn]').map((part, j) => 
                    j === 0 ? part : <React.Fragment key={`btn-${i}-${j}`}><CTABlock />{part}</React.Fragment>
                  );
                }
                return child;
              })}
            </p>
          );
        }
        
        return <p>{children}</p>;
      },
      h1: ({ children }) => <h1>{children}</h1>,
      h2: ({ children }) => <h2>{children}</h2>,
      h3: ({ children }) => <h3>{children}</h3>,
      h4: ({ children }) => <h4>{children}</h4>,
      blockquote: ({ children }) => <blockquote>{children}</blockquote>,
    },
    list: {
      bullet: ({ children }) => <ul>{children}</ul>,
      number: ({ children }) => <ol>{children}</ol>,
    },
    listItem: {
      bullet: ({ children }) => <li>{children}</li>,
      number: ({ children }) => <li>{children}</li>,
    },
    types: {
      code: ({ value }) => {
        if (!value?.code) return null;
        const language = value.language || 'text';
        return <CodeBlock code={value.code} language={language} />;
      },
      image: ({ value }) => {
        // Debug log to see the image structure
        console.log('Image value in portable text:', value);
        
        // 1. Handle case where the image is in reference format
        if (value?.asset?._ref) {
          // This is the common Sanity format with an _ref for the image
          try {
            // Convert Sanity image _ref to a full URL
            // Example: image-abcd1234-1920x1080-jpg becomes https://cdn.sanity.io/images/project-id/dataset/abcd1234-1920x1080.jpg
            const refParts = value.asset._ref.split('-');
            console.log('Image ref parts:', refParts);
            
            if (refParts[0] === 'image' && refParts.length >= 3) {
              // Use the actual project ID and dataset from your Sanity configuration
              const projectId = 'e4zkjk7p';
              const dataset = 'production';
              
              // For the format, we need to extract it properly
              const format = refParts[refParts.length - 1];
              
              // Construct the Sanity CDN URL
              let url = '';
              if (refParts.length === 3) {
                // Simple case: image-{id}-{format}
                url = `https://cdn.sanity.io/images/${projectId}/${dataset}/${refParts[1]}.${format}`;
              } else if (refParts.length >= 4) {
                // Complex case with dimensions: image-{id}-{dimensions}-{format}
                // We need all parts between 'image' and the format
                const middleParts = refParts.slice(1, -1).join('-');
                url = `https://cdn.sanity.io/images/${projectId}/${dataset}/${middleParts}.${format}`;
              }
              
              console.log('Generated image URL:', url);
              
              return (
                <BlogPostImage 
                  src={url} 
                  alt={value.alt || 'Blog post image'} 
                  width={value.width} 
                  height={value.height}
                />
              );
            }
          } catch (error) {
            console.error('Error parsing image reference:', error);
          }
        }
        
        // 2. Handle case where URL is provided directly
        if (value?.asset?.url) {
          console.log('Direct image URL found:', value.asset.url);
          return (
            <BlogPostImage 
              src={value.asset.url} 
              alt={value.alt || 'Blog post image'} 
              width={value.width} 
              height={value.height}
            />
          );
        }
        
        // 3. Handle Sanity's nested structure in localized content
        if (value?.asset?.source?.url || value?.asset?.source?.id) {
          const url = value.asset.source.url || `https://cdn.sanity.io/images/e4zkjk7p/production/${value.asset.source.id}.jpg`;
          console.log('Found nested image URL:', url);
          return (
            <BlogPostImage 
              src={url} 
              alt={value.alt || 'Blog post image'} 
              width={value.width} 
              height={value.height}
            />
          );
        }
        
        console.log('No valid image data found in:', value);
        return null; // Return null if no valid image structure is found
      },
    },
  };

  return (
    <WallpaperWrapper>
      <Helmet prioritizeSeoTags={true}>
        <title>{title}</title>
        <meta name="description" content={excerpt || title} />

        {/* Open Graph tags for social sharing */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={excerpt || title} />
        <meta property="og:image" content={mainImageUrl} />
        <meta property="og:image:alt" content={title} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="webcity.dev" />

        {/* Twitter Card tags for social sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@webcitydev" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={excerpt || title} />
        <meta name="twitter:image" content={mainImageUrl} />
        <meta name="twitter:image:alt" content={title} />

        {/* Keywords and canonical URL */}
        <meta name="keywords" content={`${categories?.map(cat => cat.title).join(', ')}, web development, programming, blogs, tech news, web agency, business`} />
        <link rel="canonical" href={canonicalUrl} />

        {/* Google Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "image": mainImageUrl,
            "author": {
              "@type": "Person",
              "name": authorName
            },
            "publisher": {
              "@type": "Organization",
              "name": "webcity.dev",
              "logo": {
                "@type": "ImageObject",
                "url": "https://webcity.dev/logo192.png"
              }
            },
            "datePublished": publishedAt,
            "dateModified": finalPost._updatedAt,
            "description": excerpt || finalPost?.metaDescription || title,
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": canonicalUrl
            },
            "keywords": categories?.map(cat => cat.title).join(', '),
          })}
        </script>
      </Helmet>
      <BlocksContainer>
        <Article>
          <BlogInfo>
            <TopInfoContainer>
              <Categories>
                {categories?.map(category => (
                  <Category key={category._id}>{category.title}</Category>
                ))}
              </Categories>
            </TopInfoContainer>
            <BlogHeadline>{title}</BlogHeadline>
            <ReadingTime>{readingTime} {t('blog.readingTime', 'min read')}</ReadingTime>
            <AuthorInfoContainer>
              <AuthorSection>
                {authorImage && <AuthorImage src={authorImage} alt={authorName} />}
                <AuthorInfo>
                  <span>{authorName}</span>
                  <span>{formatDate(publishedAt)}</span>
                </AuthorInfo>
              </AuthorSection>
              <AccessibilityControls>
                <TextToSpeechButton 
                  onClick={(e) => {
                    if (navigator.share) {
                      e.stopPropagation();
                      navigator.share({
                        title: title || t('blog.share.defaultTitle', 'Check out this blog post on webcity.dev'),
                        text: excerpt || t('blog.share.defaultDescription', 'I found this interesting article on webcity.dev'),
                        url: canonicalUrl,
                      }).catch(err => console.log('Error sharing:', err));
                    } else {
                      // Fallback to copy to clipboard
                      e.stopPropagation();
                      navigator.clipboard.writeText(canonicalUrl)
                        .then(() => toast.success(t('blog.share.copySuccess', 'Link copied to clipboard!'), toastConfig))
                        .catch(err => console.log('Error copying to clipboard:', err));
                    }
                  }}
                  aria-label={t('blog.share.button', 'Share')}
                >
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" fill="currentColor"/>
                  </svg>
                  {t('blog.share.button', 'Share')}
                </TextToSpeechButton>
                <TextToSpeechButton 
                  onClick={toggleTextToSpeech} 
                  aria-label={isSpeaking ? t('blog.textToSpeech.stop', 'Stop reading') : t('blog.textToSpeech.start', 'Read aloud')}
                  isSpeaking={isSpeaking}
                >
                  {isSpeaking ? (
                    <>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="6" y="5" width="4" height="14" rx="1" fill="currentColor" />
                        <rect x="14" y="5" width="4" height="14" rx="1" fill="currentColor" />
                      </svg>
                      {t('blog.textToSpeech.stop', 'Stop')}
                    </>
                  ) : (
                    <>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5v14l11-7z" fill="currentColor"/>
                      </svg>
                      {t('blog.textToSpeech.start', 'Listen')}
                    </>
                  )}
                </TextToSpeechButton>
              </AccessibilityControls>
            </AuthorInfoContainer>
            <Content>
              <PortableText value={bodyContent} components={portableTextComponents} />
            </Content>
          </BlogInfo>
        </Article>

        {/* Back Button */}
        <BackButton link={"blogs"} />
        
        {/* Toast Container */}
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </BlocksContainer>
    </WallpaperWrapper>
  );
};

export default BlogPost;
