import React from 'react';
import { Link } from 'react-router-dom';
import { BlogPost, BlogContent, BlogTitle, BlogMeta, CategorySpan, DateSpan, BlogExcerpt, AuthorSection, AuthorImage, AuthorName, AuthorInfo } from './BlogPostCard.styles';

const BlogPostCard = ({ post, formatDate }) => {
  return (
    <Link to={`/blog/${post.slug}`} style={{ textDecoration: 'none' }}>
      <BlogPost>
        <BlogContent>
          <CategorySpan>{post.categories.map(cat => cat.title).join(', ')}</CategorySpan>
          <BlogTitle>
            {post.title}
          </BlogTitle>
          <BlogExcerpt>{post.description || 'No description available'}</BlogExcerpt>
          <BlogMeta>
            <AuthorSection>
              {post.author?.image?.asset?.url && (
                <AuthorImage src={post.author.image.asset.url} alt={post.author?.name} />
              )}
              <AuthorInfo>
                <AuthorName>{post.author?.name || "webcity.dev Team"}</AuthorName>
                <DateSpan>{formatDate(post.publishedAt)}</DateSpan>
              </AuthorInfo>
            </AuthorSection>
          </BlogMeta>
        </BlogContent>
      </BlogPost>
    </Link>
  );
};

export default BlogPostCard;
