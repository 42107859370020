import styled from 'styled-components';

export const BlogPost = styled.article`
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 1.25rem;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #fff;
  height: 320px;
  display: flex;
  flex-direction: column;
  box-shadow: none;

  &:hover {
    transform: translateY(-3px);
    border-color: #d1d1d1;
  }
`;

export const BlogImageWrapper = styled.div`
  width: 100%;
  height: 150px;
  overflow: hidden;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(to top, rgba(0,0,0,0.4), transparent);
  }
`;

export const BlogImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const CategorySpan = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
  color: #FF1493;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 0.25rem;
`;

export const BlogContent = styled.div`
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  height: 100%;
  position: relative;
`;

export const BlogTitle = styled.h2`
  font-size: 1.25rem;
  margin: 0.3rem 0;
  line-height: 1.3;
  font-weight: 600;
  color: #333;
`;

export const BlogExcerpt = styled.p`
  font-size: 0.9rem;
  color: #666;
  line-height: 1.6;
  margin: 0.4rem 0 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 400;
  letter-spacing: 0.01em;
`;

export const BlogMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  width: 100%;
  color: #666;
  margin-top: auto;
  padding-top: 0.8rem;
  border-top: 1px solid #f0f0f0;
`;

export const AuthorSection = styled.div`
  display: flex;
  align-items: center;
`;

export const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AuthorImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.8rem;
  border: 1px solid #f0f0f0;
`;

export const AuthorName = styled.span`
  font-size: 0.9rem;
  color: #555;
  font-weight: 500;
  line-height: 1.3;
`;

export const DateSpan = styled.span`
  font-size: 0.75rem;
  color: #888;
  margin-top: 0.1rem;
`;