import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Sidebar = styled.aside`
  flex: 1;
  height: max-content;
  position: sticky;
  top: 120px;
  background: white;
  border-radius: 1.25rem;
  padding: 1.5rem;
  border: 1px solid #e6e6e6;
`;

export const HeaderSection = styled.div`
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 0.8rem;
`;

export const SidebarHeading = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  position: relative;
  margin: 0;
`;

export const RecentPostsList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const RecentPost = styled.li`
  margin: 0;
  position: relative;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  
  a {
    display: block;
    text-decoration: none;
    color: var(--text-color);
    padding: 0.8rem 1rem 0.8rem 1.2rem;
    border-radius: 0.5rem;
  }
  
  &:hover {
    border-color: #e6e6e6;
  }
`;

export const RecentPostTitle = styled.h4`
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  font-weight: 600;
  color: #333;
  line-height: 1.4;
  
  &:hover {
    color: #FF69B4;
  }
`;

export const RecentPostMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-top: 0.4rem;
`;

export const PostCategory = styled.span`
  font-size: 0.75rem;
  color: #FF69B4;
  font-weight: 600;
  letter-spacing: 0.3px;
`;

export const RecentPostDate = styled.span`
  font-size: 0.75rem;
  color: #888;
  display: flex;
  align-items: center;
  
  &:before {
    content: "•";
    margin-right: 0.5rem;
    color: #ccc;
  }
`;
