import styled from 'styled-components';

export const CategoryFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1.2rem;
  background: #ffffff;
  border-radius: 1.25rem;
  border: 1px solid #e6e6e6;

  @media (max-width: 768px) {
    padding: 0.75rem;
    gap: 0.75rem;
    justify-content: center;
  }
`;

export const CategoryButton = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  background: ${props => {
    if (props.isSelected) {
      return ['All', 'Tous'].includes(props.children) ? '#333' : '#FF69B4';
    }
    return 'white';
  }};
  color: ${props => props.isSelected ? 'white' : '#555'};
  font-size: 0.95rem;
  font-weight: ${props => props.isSelected ? '600' : '500'};
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid ${props => {
    if (props.isSelected) {
      return ['All', 'Tous'].includes(props.children) ? '#333' : '#FF69B4';
    }
    return '#e6e6e6';
  }};

  &:hover {
    border-color: ${props => {
      if (props.isSelected) {
        return ['All', 'Tous'].includes(props.children) ? '#333' : '#FF69B4';
      }
      return '#d1d1d1';
    }};
    background: ${props => {
      if (props.isSelected) {
        return ['All', 'Tous'].includes(props.children) ? '#333' : '#FF69B4';
      }
      return '#fafafa';
    }};
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
`;