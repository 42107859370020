import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { 
  Sidebar, 
  SidebarHeading, 
  RecentPostsList, 
  RecentPost, 
  RecentPostTitle, 
  RecentPostDate,
  RecentPostMeta,
  PostCategory,
  HeaderSection
} from './RecentPostsSidebar.styles';

// Helper to check if a post is new (less than 7 days old)
const isNewPost = (publishedDate) => {
  const now = new Date();
  const published = new Date(publishedDate);
  const diffTime = Math.abs(now - published);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 7;
};

const RecentPostsSidebar = ({ recentPosts, formatDate }) => {
  const { t } = useTranslation();

  return (
    <Sidebar>
      <HeaderSection>
        <SidebarHeading>{t('blogs.recentPosts')}</SidebarHeading>
      </HeaderSection>
      
      <RecentPostsList>
        {recentPosts.slice(0, 4).map((post, index) => (
          <RecentPost key={post._id} $isFirst={index === 0}>
            <Link to={`/blog/${post.slug}`}>
              <RecentPostTitle>
                {post.title}
              </RecentPostTitle>
              
              <RecentPostMeta>
                {post.categories && post.categories.length > 0 && (
                  <PostCategory>
                    {post.categories[0].title}
                  </PostCategory>
                )}
                <RecentPostDate>{formatDate(post.publishedAt)}</RecentPostDate>
              </RecentPostMeta>
            </Link>
          </RecentPost>
        ))}
      </RecentPostsList>
    </Sidebar>
  );
};

export default RecentPostsSidebar;